import React from 'react'

const Home = () => {
  return (
    <div>
      <div className=''>
        <div className='relative'>
          <div className='flex justify-between py-4 px-6'>
            <img className='h-20' src='logo.png' />
            <button className='mt-2 bluebtn font-bold py-0 h-11 rounded-xl px-4'>Coming Soon</button>
          </div>
          <img src='bgelem.png' className='absolute md:top-0 top-24 -z-10 opacity-70' />
          <div className='flex md:block justify-center w-full'>
            <div className='ml-0 md:ml-10 mt-14 w-3/4 md:w-1/2' data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos="zoom-in-right" data-aos-delay="100">
              <h1 className='text-4xl font-bold text-pink-600'><span className='text-blue-600 mr-2'>Matic</span>Force</h1>
              <h2 className='mt-4 text-gray-200 font-mono'>MaticForce is focused on creating a more sustainable and equitable DeFi ecosystem. This project is building a decentralized platform that allows users to participate in a wide range of financial activities while also promoting social and environmental impact. With its innovative approach to DeFi, this project has the potential to transform the way we think about finance and make it more accessible and inclusive for everyone.</h2>
            </div>
          </div>
        </div>
        <div data-aos="fade-up" className='mt-20 text-center font-bold text-4xl text-gray-400 animate-pulse'>
          COMING SOON
        </div>
        <div className='bg flex justify-between mt-10 p-4'>
          <img data-aos="zoom-out" src='btc.png' className='h-14 opacity-40' />
          <img data-aos="zoom-out" data-aos-delay="200" src='eth.png' className='h-14 opacity-40' />
          <img data-aos="zoom-out" data-aos-delay="400" src='matic.png' className='h-14 opacity-40' />
          <img data-aos="zoom-out" data-aos-delay="600" src='uniswap.png' className='h-14 opacity-40' />
        </div>
        
        <div data-aos="fade-up" className='mt-20 text-center font-bold text-4xl text-gray-200'>
          Focus on solving bigger problems
        </div>
        <img data-aos="fade-in" data-aos-delay="400" src='blockchain.png' className='mt-4' />
        <div className='mt-20 flex justify-center mb-10 text-center font-bold md:text-3xl text-2xl text-gray-200'>
          Powered by <img src='matic.png' className='md:h-12 h-10 ml-4 mr-4' /> Polygon Chain
        </div>
      </div>
    </div>
  )
}

export default Home