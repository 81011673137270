import Home from './components/Home'
import Aos from 'aos';
import "aos/dist/aos.css"
import { useEffect } from 'react';

function App() {
  useEffect(() => {
    Aos.init();
  },[]) 

  return (
    <div className="App">
      <Home />
    </div>
  );
}

export default App;
